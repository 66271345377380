import { GET, PUT } from 'src/api/oceanApi';
import { responseError } from 'src/app/sagas';
import { showNotification } from 'src/components/Notification/actions';
import { EmployeeSchema } from 'src/generated';
import { Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* getProfile() {
  try {
    const employee: EmployeeSchema = yield call(GET, '/employee');

    yield put(actions.setProfile({ value: employee, hasData: true }));
  } catch (e) {
    yield all([call(responseError, e), put(actions.setProfile({ error: e as Error, hasData: false }))]);
  }
}

function* updateEmployee({ payload }: Action<EmployeeSchema>) {
  try {
    yield put(actions.setProfileFormLoading(true));

    yield call(PUT, '/employee', payload);

    yield put(
      showNotification({
        variant: 'success',
        title: '',
        messageId: 'notification.success.text.profile_change',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setProfileFormLoading(false));
  }
}

export default [takeLatest(actions.getProfile, getProfile), takeLatest(actions.updateEmployee, updateEmployee)];
