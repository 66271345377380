import { Route } from '@core-ui/redux-router';
import { ROUTES } from 'src/app/consts/routes';
import State from 'src/app/types/state';
import { SETTINGS_TABS } from 'src/pages/settings/consts';
import ProfileRoot from 'src/pages/settings/ProfileRoot';
import { getProfile } from 'src/pages/settings/ProfileRoot/actions';
import SettingsRoot from 'src/pages/settings/SettingsRoot';

const key: keyof State = 'settings';

export const SETTINGS_ROUTE: Route = {
  key,
  path: `/:boatId([0-9]+)/:route(${ROUTES.SETTINGS})`,
  component: SettingsRoot,
  routes: [
    {
      key,
      path: `/:boatId([0-9]+)/:route(${ROUTES.SETTINGS})/:settingsTab(${SETTINGS_TABS.PROFILE})`,
      component: ProfileRoot,
      effects: [{ events: ['on_enter'], trigger: getProfile }],
    },
  ],
};

const routes: Route[] = [SETTINGS_ROUTE];

export default routes;
