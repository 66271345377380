import { setRequiredFields } from 'src/app/utils/app';
import { isValidPassword } from 'src/app/utils/validate';
import { IChangePassword } from 'src/pages/settings/types';
import { ValidationErrors } from 'final-form';
import { FormattedMessage } from 'react-intl';

export default (values: IChangePassword): ValidationErrors => {
  const errors: ValidationErrors = {};

  setRequiredFields(values, errors, ['old_password', 'new_password', 'confirm_new_password']);

  if (!isValidPassword(values.old_password)) {
    errors.old_password = <FormattedMessage id="form.errors.password_must_be" />;
  }

  if (!isValidPassword(values.new_password)) {
    errors.new_password = <FormattedMessage id="form.errors.password_must_be" />;
  }

  if (!isValidPassword(values.confirm_new_password)) {
    errors.confirm_new_password = <FormattedMessage id="form.errors.password_must_be" />;
  }

  if (values.new_password !== values.confirm_new_password) {
    errors.new_password = <FormattedMessage id="form.errors.passwords_do_not_match" />;
    errors.confirm_new_password = <FormattedMessage id="form.errors.passwords_do_not_match" />;
  }

  return errors;
};
