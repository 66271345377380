import { BREAKPOINTS } from '@core-ui/styles';
import Tabs from '@core-ui/tabs';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { IRouteParams, ROUTES } from 'src/app/consts/routes';
import { vehicleIdSelector } from 'src/app/selectors';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useParams } from 'react-router';
import { SETTINGS_TABS } from '../consts';
import useStyles from './styles';

const SettingsTabs: FC = () => {
  const styles = useStyles();
  const boatId = useAppSelector(vehicleIdSelector);
  const { settings_tab } = useParams<IRouteParams>();

  const tabs = [SETTINGS_TABS.PROFILE].map((tab) => ({
    value: tab,
    label: <FormattedMessage id={`tab.${tab}`} />,
    to: buildURL(`/${boatId}/${ROUTES.SETTINGS}/${SETTINGS_TABS.PROFILE}`),
  }));

  return (
    <Paper elevation={0} sx={styles.paper}>
      <Grid item marginTop="auto" padding="0 20px" width="100%" maxWidth={BREAKPOINTS.XL}>
        <Tabs size="small" tabs={tabs} active={settings_tab || SETTINGS_TABS.PROFILE} />
      </Grid>
    </Paper>
  );
};

export default SettingsTabs;
