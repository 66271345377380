import { MySkyLoader } from '@core-ui/icons';
import { HEIGHT_SIZE } from '@core-ui/styles';
import Container from '@mui/material/Container';
import { SxProps, Theme } from '@mui/material/styles';
import { router } from 'src/configureStore';
import SettingsModals from 'src/pages/settings/Modals';
import { SETTINGS_ROUTE } from 'src/pages/settings/routes';
import React, { CSSProperties } from 'react';
import SettingsTabs from '../SettingsTabs';

const containerStyle: SxProps<Theme> = {
  paddingTop: `${HEIGHT_SIZE.tabsHeight}px`,
  height: `calc(100vh - ${HEIGHT_SIZE.headerHeight}px)`,
};

const fallbackStyle: CSSProperties = {
  margin: 'auto',
};

const SettingsRoot = () => {
  return (
    <>
      <SettingsTabs />

      <Container maxWidth="xl" sx={containerStyle}>
        {SETTINGS_ROUTE.routes && router.render(SETTINGS_ROUTE.routes, <MySkyLoader style={fallbackStyle} />)}
      </Container>

      <SettingsModals />
    </>
  );
};

export default SettingsRoot;
