import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { handleActions } from 'redux-actions';
import * as actions from './actions';
import { ChangePasswordModalData } from './command';

export type IModalsData = ChangePasswordModalData;

export interface IModalsState {
  data: Nullable<IModalsData>;
  opened: boolean;
  loading: boolean;
}

const defaultState = Immutable<IModalsState>({
  data: null,
  opened: false,
  loading: false,
});

export default handleActions<typeof defaultState>({}, reducer<typeof defaultState>(actions.reducerName, defaultState));
